<template>
  <div class="upload-ctn">
    <div v-show="!loading" class="w_100_per dis_flex ali_it_cen flex_wrap">
      <div
        v-for="(item, index) in fileList"
        :key="`${index}-${item.url}`"
        class="upload-preview-img"
        @click="handleClickPreview(item)"
      >
        <img
          :src="isImage(item.url) ? item.url : '/images/file.png'"
          style="object-fit: cover"
        />
        <div v-if="item.name" class="file-name">{{ item.name }}</div>
        <van-icon
          v-if="!disabled"
          name="clear"
          class="icon-delete"
          @click.stop="handleDel(index, item)"
        />
      </div>

      <van-uploader
        v-show="!disabled"
        ref="preview"
        v-model="fileList"
        :max-count="maxCount"
        :multiple="multiple"
        :max-size="maxSize"
        :accept="isDefaultAccept ? acceptDefault : accept"
        :disabled="disabled || loading"
        :preview-image="false"
        :after-read="toHandle"
      >
      </van-uploader>
      <div v-if="placeholder" class="placeholder">
        {{ placeholder }}
      </div>
    </div>
    <van-loading v-show="loading">正在为您上传中，请耐心等待</van-loading>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
import { ImagePreview, Toast } from "vant";
import Compressor from "compressorjs";
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    maxCount: {
      type: Number,
      default: () => 9
    },
    maxSize: {
      type: Number,
      default: () => 50 * 1024 * 1024
    },
    accept: {
      type: String,
      default: () => "image/*"
    },
    isDefaultAccept: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    processFileNameUrlAuto: {
      // 是否自动按fileName fileUrl处理value
      type: Boolean,
      default: () => true
    },
    placeholder: {
      default: "",
      type: String
    },
    // 预览前校验
    // 如果不满足previewVali，则不允许用户进行跳转预览
    // 场景举例：pc端浏览器返回，会触发页面重载。导致预览视频文件时返回表单清空。而手机端不会
    previewVali: {
      type: String,
      defualt: () => "" // mobile pc
    },
    multiple: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      fileList: [] // 文件上传
    };
  },
  created() {},
  computed: {
    acceptDefault() {
      return this.isMobile() ? "image/*,video/*" : "*";
    }
  },
  watch: {
    value(val) {
      this.fileList = this.processFileNameUrlAutoFn(val ? val : []);
    }
  },
  mounted() {
    this.fileList = this.processFileNameUrlAutoFn(this.value);
  },
  methods: {
    // 校验是手机端还是pc端
    checkDevice() {
      const userAgent = navigator.userAgent;

      // 检查设备是否支持触摸
      const hasTouchScreen =
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

      // 获取视口宽度
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (
        /Android|webOS|iPhone|iPad|Mobile|iPod|BlackBerry|IEMobile|Mozilla|Opera Mini/i.test(
          userAgent
        ) &&
        hasTouchScreen && // 定义桌面设备的可能特征：不支持触摸且屏幕宽度较大
        screenWidth < 800
      ) {
        return "mobile";
      } else {
        return "pc";
      }
    },
    // 根据是否开启自动处理，处理传入的数据
    processFileNameUrlAutoFn(arr = []) {
      if (this.processFileNameUrlAuto) {
        return arr.map((item) => ({
          ...item,
          url: item.fileUrl,
          name: item.fileName
        }));
      }
      return arr;
    },
    // 根据是否开启自动处理，处理出去的数据
    processToFileNameUrlAutoFn(arr = []) {
      if (this.processFileNameUrlAuto) {
        return arr.map((item) => ({
          ...item,
          fileUrl: item.url,
          fileName: item.name
        }));
      }
      return arr;
    },
    isMobile() {
      let userAgentInfo = navigator.userAgent;
      let Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      ];
      let getArr = Agents.filter((i) => userAgentInfo.includes(i));
      return getArr.length ? true : false;
    },
    isVideo(url) {
      return /(mp4|mov|mwv|flv|avi)$/i.test(url);
    },
    isImage(url) {
      return /(jpg|png|jpeg)$/i.test(url);
    },
    isJPG(url) {
      return /(jpg)$/i.test(url);
    },
    handleDel(index, item) {
      this.fileList.splice(index, 1);
      this.$emit("input", this.processToFileNameUrlAutoFn(this.fileList));
      this.$emit("del", index, item);
    },
    convertToJPG(imgfile, quality) {
      return new Promise((resp, rej) => {
        try {
          let _name = imgfile.name;
          _name = `${_name.slice(0, _name.lastIndexOf("."))}.jpg`;

          let read = new FileReader();
          read.readAsDataURL(imgfile);

          read.onload = function (e) {
            const imgUrl = this.result;
            let img = new Image();
            img.src = imgUrl;
            img.onload = function () {
              let canvas = document.createElement("canvas");
              canvas.width = img.naturalWidth;
              canvas.height = img.naturalHeight;
              let ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              let dataURL = canvas.toDataURL("image/jpeg", quality);
              function dataURLToBlob(dataurl) {
                let arr = dataurl.split(",");
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], _name, {
                  type: mime
                });
              }
              resp(dataURLToBlob(dataURL));
            };
          };
        } catch (error) {
          rej(error);
        }
      });
    },
    handleBeforeImageToJPG(file) {
      let _file = file;
      let _this = this;
      console.info("转换成JPG格式图片……");
      return new Promise((resp, rej) => {
        try {
          _this
            .convertToJPG(_file, 1)
            .then((rlt) => {
              console.info("转换成功！");
              resp(rlt);
            })
            .catch((err) => {
              rej(err);
            });
        } catch (error) {
          rej(error);
        }
      });
    },
    handleBeforeRead(file) {
      const _file = file;
      return new Promise((resolve, reject) => {
        console.info("压缩图片文件……");
        // 图片大于5mb
        new Compressor(_file, {
          quality: 0.8,
          success(result) {
            const newFile = new File([result], _file.name, {
              type: "mimeType"
            });
            if (newFile.size > 5 * 1024 * 1024) {
              Toast("图片文件大小过大，请重试");
              reject("图片文件大小过大，请重试");
            }
            console.info("压缩完成");
            resolve(newFile);
          },
          error(err) {
            Toast("图片压缩失败");
            reject(err);
          }
        });
      });
    },
    async toHandle(files) {
      if (Array.isArray(files)) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          await this.handleUploadImg(file);
        }
      } else await this.handleUploadImg(files);
    },
    async handleUploadImg(file) {
      if (file == false) return;
      try {
        this.loading = true;
        let _file = file.file;
        if (this.isImage(_file.name) && !this.isJPG(_file.name))
          _file = await this.handleBeforeImageToJPG(_file);
        if (this.isImage(_file.name) && _file.size > 5 * 1024 * 1024)
          _file = await this.handleBeforeRead(_file);

        console.info("上传图片……");
        const resp = await uploadFile(_file);
        console.info("上传成功");
        const item = {
          name: file.file.name,
          url: resp.ossAccessUrl,
          uploaded: true
        };
        let list = [...this.fileList, item].filter((e) => e.uploaded);
        this.$set(this, "fileList", list);
        this.$emit("input", this.processToFileNameUrlAutoFn(this.fileList));
        this.$emit("upload", item);
      } catch (e) {
        Toast("上传失败");
        console.warn(e);
        this.fileList.pop();
      } finally {
        this.loading = false;
      }
    },
    handleClickPreview(file) {
      if (this.loading) return;
      const that = this;
      if (!this.isImage(file.url)) {
        if (this.previewVali && this.previewVali != this.checkDevice()) {
          Toast("该设备端暂不支持预览");
          return;
        } else {
          window.location.href = file.url;
        }
      } else {
        const previewImgs = this.fileList.filter((item) =>
          that.isImage(item.url)
        );
        const _imgsArr = previewImgs.map((item) => item.url);
        const index = previewImgs.findIndex((item) => item.url == file.url);
        ImagePreview({ images: _imgsArr, startPosition: index });
      }
    },
    isImageFile(file) {
      if (!file || !file.type) {
        return false;
      }
      return file.type.indexOf("image/") === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.placeholder {
  text-align: center;
  color: #666;
}
.upload-preview-img {
  width: calc(25vw - 6px);
  height: calc(25vw - 6px);
  flex: 0 0 calc(25vw - 6px);

  display: inline-block;
  margin: 0 24px 24px 0;
  &:nth-child(3n) {
    margin-right: 0px;
  }
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .file-name {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    width: 100%;
    text-align: center;

    overflow: hidden;
    font-size: 16px;
    line-height: 32px;
    height: 32px;
    z-index: 10;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-delete {
    position: absolute;
    top: -16px;
    right: -16px;
    z-index: 10;
    color: red;
  }
}
:deep(.van-uploader__upload) {
  width: calc(25vw - 6px);
  height: calc(25vw - 6px);
  flex: 0 0 calc(25vw - 6px);
  margin-bottom: 24px;
}
</style>
